import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { AuthService } from './auth.service';
import { TranslatePipe } from '../../utils/pipes';
type ResourceType = 'language' | 'yesno' | 'month';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    // injections
    authService = inject(AuthService);
    translatePipe = inject(TranslatePipe);

    resources: Record<string, any[]> = {};

    public addResource(type: ResourceType, resource: any[]) {
        this.resources[type] = resource;
    }

    public getResource(type: ResourceType) {
        return this.resources[type];
    }

    public getResourceById(type: string, id: string) {
        const result = this.resources[type].find((item) => {
            return item.id === id;
        });
        return result || { title: 'not found', value: 'not found' };
    }

    public getResourceByCode(type: string, code: string) {
        const result = this.resources[type].find((item) => {
            return item?.code?.toLowerCase() === code?.toLowerCase();
        });
        return result || null;
    }

    public getYesNoOption(value) {
        return this.getYesNoOptions().find((item) => {
            return item.value == value;
        });
    }

    public getYesNoOptions() {
        return [
            { title: this.translatePipe.transform('misc_yes'), value: true },
            { title: this.translatePipe.transform('misc_no'), value: false }
        ];
    }
}

@Pipe({ name: 'resource', standalone: true })
export class resourcePipe implements PipeTransform {
    resourceService = inject(ResourceService);
    transform(value: any, type: string, identifier: 'code' | 'id'): any {
        if (value && identifier === 'code') {
            return this.resourceService.getResourceByCode(type, value);
        }
        if (value && identifier === 'id') {
            return this.resourceService.getResourceById(type, value);
        }
        return '-';
    }
}

@Pipe({ name: 'yesNo', standalone: true })
export class YesNoPipe implements PipeTransform {
    resourceService = inject(ResourceService);
    translatePipe = inject(TranslatePipe);
    transform(value: any, strict?: boolean, hasNa?: boolean): any {
        if (hasNa && !value && value !== false) {
            return this.translatePipe.transform('misc_na');
        }
        if (strict && value !== true && value !== false) {
            return '-';
        } else return this.resourceService.getYesNoOption(value)?.title ?? '-';
    }
}
